import { createAction, props } from '@ngrx/store';

import { UserProfile } from '../../core/user/user.interface';

export const loadCompletedTasksSuccess = createAction('[User] Load Completed Tasks Success', props<{ completedTasks: string[] }>());

export const loadCompletedTasksError = createAction('[User] Load Completed Tasks Error', props<{ error: any }>());

export const addUserProfile = createAction('[User] Add User Profile', props<{ userProfile: UserProfile, shouldUpdateCompletedTasks?: boolean }>());

export const addCompletedTasks = createAction('[User] Add Completed Tasks', props<{ taskIds: string[] }>());

export const addCompletedTasksSuccess = createAction('[User] Add Completed Tasks Success', props<{ completedTasks: string[] }>());

export const addCompletedTasksError = createAction('[User] Add Completed Tasks Error', props<{ error: any }>());

export const removeCompletedTask = createAction('[User] Remove Completed Task', props<{ taskId: string }>());

export const removeCompletedTaskSuccess = createAction('[User] Remove Completed Task Success', props<{ completedTask: string }>());

export const removeCompletedTaskError = createAction('[User] Remove Completed Task Error', props<{ error: any }>());

export const logout = createAction('[User] Logout');
