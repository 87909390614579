import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { isNil, omitBy } from 'lodash-es';
import { filter, map } from 'rxjs/operators';

import {
  deleteTaskMutation,
  DeleteTasksMutationResponse,
  getTasksQuery,
  GetTasksQueryResponse,
  insertTaskMutation,
  InsertTaskMutationResponse,
  totalTasksCountQuery,
  updateTaskMutation,
  UpdateTaskMutationResponse,
} from './task.graphql';
import { Task } from './task.interface';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private apollo: Apollo) {}

  getTotalTasksCount$() {
    return this.apollo.watchQuery<any>({
      query: totalTasksCountQuery,
    }).valueChanges.pipe( filter(query => !!query), map(query => query.data.tasks_aggregate.aggregate.count));
  }

  getTasks$(categoryId: string, options = {}) {
    return this.apollo.watchQuery<GetTasksQueryResponse>({
      query: getTasksQuery,
      variables: {
        categoryId
      },
      ...options
    }).valueChanges.pipe(filter(query => !!query), map(query => query.data.tasks));
  }

  addTask(task: Task) {
    return this.apollo.mutate<InsertTaskMutationResponse>({
      mutation: insertTaskMutation,
      variables: { objects: omitBy(task, isNil) }
    }).pipe(filter(mutation => !!mutation), map(mutation => mutation.data.insert_tasks.returning));
  }

  updateTask(id: string, task: Task) {
    return this.apollo.mutate<UpdateTaskMutationResponse>({
      mutation: updateTaskMutation,
      variables: { id, task: omitBy(task, isNil) }
    }).pipe(filter(mutation => !!mutation), map(mutation => mutation.data.update_tasks.returning));
  }

  deleteTask(id: string) {
    return this.apollo.mutate<DeleteTasksMutationResponse>({
      mutation: deleteTaskMutation,
      variables: { id }
    }).pipe(
    filter(mutation => !!mutation),
    map(mutation => mutation.data.delete_tasks.returning),
    map(tasks => tasks.map(task => task.id)));
  }
}
