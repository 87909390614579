import gql from 'graphql-tag';

import { Category } from './category.interface';

export interface AllCategoriesQuery {
    categories: Category[];
}

export interface InsertCategoryMutationResponse {
  insert_categories: {
    returning: Category[];
  };
}

export interface UpdateCategoryMutationResponse {
  update_categories: {
    returning: Category[];
  };
}

export interface DeleteCategoriesMutationResponse {
  delete_categories: {
    returning: Array<{ id: string }>
  };
}

export const allCategoriesQuery = gql`
query AllCategories {
    categories {
      id
      name
      description
      sortOrder
    }
}`;

export const insertCategoryMutation = gql`
mutation InsertCategory($objects: [categories_insert_input!]!) {
  insert_categories(objects: $objects) {
    returning {
      id
      name
      description
      sortOrder
    }
  }
}`;

export const updateCategoryMutation = gql`
mutation UpdateCategory($id: uuid, $category: categories_set_input) {
  update_categories(where: {id: {_eq: $id}}, _set: $category) {
    returning {
      id
      name
      description
      sortOrder
    }
  }
}`;

export const deleteCategoryMutation = gql`
mutation DeleteCategory($id: uuid) {
  delete_categories(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}`;
