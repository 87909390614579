<mat-card>
    <mat-card-header id="header-category">
        <mat-card-title *ngIf="selectedCategory; else chooseCategory">{{selectedCategory.name}}</mat-card-title>
        <ng-template #chooseCategory><mat-card-title>Choose Category</mat-card-title></ng-template>
    </mat-card-header>
    <!-- 
    <mat-form-field id="header-search">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" />
    </mat-form-field>
    -->
    <mat-divider></mat-divider>

    <table id="table-tasks" #table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="sortOrder">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element"><div class="list-number">{{ element.sortOrder }}</div></td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Achievement</th>
            <td mat-cell *matCellDef="let element">
                <div class="list-title" [innerHTML]="element.name"></div>
                <div class="list-desc" [innerHTML]="element.description"></div>
            </td>
        </ng-container>
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">
            <mat-checkbox   (change)="toggleTaskCompletion(row.id)"
                            [checked]="completedTasks.includes(row.id)">
            </mat-checkbox>
            </td>
        </ng-container>
        <!-- 
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
        -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</mat-card>
