import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../core/auth/auth.service';
import {BehaviorSubject, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {catchError, delay, switchMap, take, tap} from 'rxjs/operators';

type FormMode = 'sign-in' | 'forgot-password'
type Status = 'loading' | 'success' | 'error'

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss']
})
export class AuthDialogComponent {
  authForm: UntypedFormGroup
  forgotPasswordForm: UntypedFormGroup
  formMode: FormMode = 'sign-in'

  authFormStatus: Status = null
  authFormMessage = ''

  resetPasswordStatus: Status = null
  resetPasswordMessage = ''

  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.authForm = fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.minLength(8), Validators.required]]
    })
    this.forgotPasswordForm = fb.group({
      email: ['', [Validators.email, Validators.required]]
    })
  }

  selectFormMode(mode: FormMode) {
    this.formMode = mode
  }

  authFormSubmit(type: 'sign-in' | 'create-user') {
    const {email, password} = this.authForm.value
    this.authFormStatus = 'loading'

    fromPromise(type === 'sign-in'
      ? this.authService.signInWithForm(email, password)
      : this.authService.createUserWithForm(email, password)).pipe(
      take(1),
      tap(() => this.authFormStatus = 'success'),
      delay(2500),
      tap(() => this.dialogRef.close()),
      catchError((err) => {
        this.authFormStatus = 'error'

        switch (true) {
          case err.message.includes('auth/user-not-found'):
            this.authFormMessage = 'User not found... try again'
            break
          case err.message.includes('auth/wrong-password'):
            this.authFormMessage = 'Invalid password'
            break
          case err.message.includes('auth/email-already-in-use'):
            this.authFormMessage = 'User already exists... try again'
            break
          default:
            this.authFormMessage = err.message
        }

        return this.authForm.valueChanges.pipe(
          take(1),
          tap(() => {
            this.authFormMessage = null
            this.authFormStatus = null
          })
        )
      })
    ).subscribe()
  }

  forgotPasswordFormSubmit() {
    const {email} = this.forgotPasswordForm.value
    this.resetPasswordStatus = 'loading'

    fromPromise(this.authService.resetPassword(email)).pipe(
      take(1),
      tap(() => this.resetPasswordStatus = 'success'),
      delay(2500),
      tap(() => {
        this.formMode = 'sign-in'
        this.resetPasswordStatus = null
        this.forgotPasswordForm.reset()
        this.forgotPasswordForm.clearValidators()
      }),
      catchError((err) => {
        this.resetPasswordStatus = 'error'

        switch (true) {
          case err.message.includes('auth/user-not-found'):
            this.resetPasswordMessage = 'Email address not found... try again'
            break;
          case err.message.includes('auth/invalid-email'):
            this.resetPasswordMessage = 'Valid email address required'
            break;
          default:
            this.resetPasswordMessage = err.message
        }

        return this.forgotPasswordForm.valueChanges.pipe(
          take(1),
          tap(() => {
            this.resetPasswordMessage = null
            this.resetPasswordStatus = null
          })
        )
      })
    ).subscribe()
  }

  signInWithGoogle() {
    fromPromise(this.authService.signInWithGoogle()).pipe(
      take(1),
      tap(() => this.authFormStatus = 'success'),
      delay(2500),
      tap(() => this.dialogRef.close()),
    ).subscribe()
  }

  onForgotPasswordBackButton() {
    this.formMode = 'sign-in'
    this.resetPasswordStatus = null
    this.resetPasswordMessage = null
    this.forgotPasswordForm.reset()
    this.forgotPasswordForm.clearValidators()
  }
}
