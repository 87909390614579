import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { CategoryService } from '../../core/categories/category.service';
import { loadTasks } from '../tasks/tasks.actions';
import * as CategoryActions from './categories.actions';
import { State as CategoryState } from './categories.reducer';

@Injectable()
export class CategoriesEffects {

    loadCategories$ = createEffect(() => this.actions$.pipe(
        ofType(CategoryActions.loadCategories),
        switchMap(() => this.categoriesService.getCategories$().pipe(
            mergeMap(categories => {
                const firstCategoryId = categories && categories.sort((a, b) => a.sortOrder - b.sortOrder)[0].id;
                return merge(
                    of(CategoryActions.loadCategoriesSuccess({categories})),
                    of(CategoryActions.selectCategory({ categoryId: firstCategoryId })),
                    of(loadTasks({ categoryId: firstCategoryId }))
                );
            }),
            catchError(error => of(CategoryActions.loadCategoriesError({error})))
        ))
    ));

    noCacheLoadCategories$ = createEffect(() => this.actions$.pipe(
        ofType(CategoryActions.noCacheLoadCategories),
        switchMap(() => this.categoriesService.getCategories$({ fetchPolicy: 'network-only' }).pipe(
            map(categories => CategoryActions.loadCategoriesSuccess({categories})),
            catchError(error => of(CategoryActions.loadCategoriesError({error})))
        ))
    ));

    addCategory$ = createEffect(() => this.actions$.pipe(
        ofType(CategoryActions.addCategory),
        mergeMap(action => this.categoriesService.addCategory(action.category).pipe(
            map(() => CategoryActions.noCacheLoadCategories()),
            catchError(error => of(CategoryActions.addCategoryError({error})))
        ))
    ));

    updateCategory$ = createEffect(() => this.actions$.pipe(
        ofType(CategoryActions.updateCategory),
        mergeMap(action => this.categoriesService.updateCategory(action.id, action.changes).pipe(
            map(() => CategoryActions.noCacheLoadCategories()),
            catchError(error => of(CategoryActions.updateCategoryError({error})))
        ))
    ));

    deleteCategory$ = createEffect(() => this.actions$.pipe(
        ofType(CategoryActions.deleteCategory),
        mergeMap(action => this.categoriesService.deleteCategory(action.id).pipe(
            map(() => CategoryActions.noCacheLoadCategories()),
            catchError(error => of(CategoryActions.deleteCategoryError({error})))
        ))
    ));

    constructor(private categoriesService: CategoryService, private actions$: Actions, private store: Store<CategoryState>) {}
}
