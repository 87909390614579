import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State as CategoryState, categoriesKey, selectIds, selectEntities, selectAll, selectTotal } from './categories.reducer';

export const selectCategoryState = createFeatureSelector<CategoryState>(categoriesKey);

export const selectSelectedCategoryId = createSelector(
  selectCategoryState,
  state => state.selectedCategoryId
);

export const selectLoadingState = createSelector(
  selectCategoryState,
  state => state.loading
);

export const selectCategoryIds = createSelector(
  selectCategoryState,
  selectIds
);

export const selectCategoryEntities = createSelector(
  selectCategoryState,
  selectEntities
);

export const selectAllCategories = createSelector(
  selectCategoryState,
  selectAll
);

export const selectCategoryTotal = createSelector(
  selectCategoryState,
  selectTotal
);

export const selectSelectedCategory = createSelector(
  selectAllCategories,
  selectSelectedCategoryId,
  (categories, categoryId) => categories.find(category => category.id === categoryId)
);
