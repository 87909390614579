import gql from 'graphql-tag';

import { Task } from './task.interface';


export interface GetTasksQueryResponse {
    tasks: Task[];
}

export interface InsertTaskMutationResponse {
  insert_tasks: {
    returning: Task[];
  };
}

export interface UpdateTaskMutationResponse {
  update_tasks: {
    returning: Task[];
  };
}

export interface DeleteTasksMutationResponse {
  delete_tasks: {
    returning: Array<{ id: string }>
  };
}

export const getTasksQuery = gql`
query GetTasks($categoryId: uuid) {
    tasks(where: {categoryId: {_eq: $categoryId}}) {
      id
      name
      description
      categoryId
      sortOrder
    }
}`;

export const totalTasksCountQuery = gql`
query TotalTasksCount {
  tasks_aggregate {
    aggregate {
      count
    }
  }
}`;

export const insertTaskMutation = gql`
mutation InsertTask($objects: [tasks_insert_input!]!) {
  insert_tasks(objects: $objects) {
    returning {
      id
      name
      description
      categoryId
      sortOrder
    }
  }
}`;

export const updateTaskMutation = gql`
mutation UpdateTask($id: uuid, $task: tasks_set_input) {
  update_tasks(where: {id: {_eq: $id}}, _set: $task) {
    returning {
      id
      name
      description
      categoryId
      sortOrder
    }
  }
}`;

export const deleteTaskMutation = gql`
mutation DeleteTask($id: uuid) {
  delete_tasks(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}`;
