import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import { Task } from '../../core/tasks/task.interface';
import { CategoriesFacade } from '../categories';
import * as TaskActions from './tasks.actions';
import { State as TasksState } from './tasks.reducer';
import {
    selectAllTasks,
    selectLoadingState,
    selectSelectedTask,
    selectTaskIds,
    selectTasksByCategory,
    selectTotalCount,
} from './tasks.selector';

@Injectable({
  providedIn: 'root'
})
export class TasksFacade {
  allTasks$ = this.store.pipe(select(selectAllTasks));
  currentTask$ = this.store.pipe(select(selectSelectedTask));
  loading$ = this.store.pipe(select(selectLoadingState), distinctUntilChanged());
  taskIds$ = this.store.pipe(select(selectTaskIds));
  totalTasksCount$ = this.store.pipe(select(selectTotalCount));
  selectedCategoryTasks = this.categoriesFacade.selectedCategory$.pipe(
    filter(res => !!res),
    switchMap(selectedCategory => this.store.pipe(select(selectTasksByCategory, selectedCategory.id)))
  );

  constructor(private store: Store<TasksState>, private categoriesFacade: CategoriesFacade) {}

  loadTotalTasksCount() {
    return this.store.dispatch(TaskActions.loadTotalTasksCount());
  }

  loadTasks(categoryId: string) {
    return this.store.dispatch(TaskActions.loadTasks({categoryId}));
  }

  selectTask(taskId: string) {
    return this.store.dispatch(TaskActions.selectTask({taskId}));
  }

  addTask(task: Task) {
    return this.store.dispatch(TaskActions.addTask({task}));
  }

  updateTask(id: string, changes: Task) {
    return this.store.dispatch(TaskActions.updateTask({id, changes}));
  }

  deleteTask(task: Task) {
    return this.store.dispatch(TaskActions.deleteTask({task}));
  }
}
