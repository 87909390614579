import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';

import { Auth0UserProfile } from '../../core/user/user.interface';
import { mapFirebaseClaimsToUserProfile } from '../../core/user/user.mapper';
import * as UserActions from './user.actions';
import { selectCompletedTasks, selectLoading, selectUserProfile } from './user.selectors';
import {User} from '@angular/fire/auth';
import {ParsedToken} from '@firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class UserFacade {
  userProfile$ = this.store.pipe(select(selectUserProfile));
  completedTasks$ = this.store.pipe(select(selectCompletedTasks));
  loading$ = this.store.pipe(select(selectLoading));

  constructor(private store: Store<any>) {}

  addProfileDetails(firebaseAuthUser: ParsedToken, shouldUpdateCompletedTasks?: boolean) {
    const userProfile = mapFirebaseClaimsToUserProfile(firebaseAuthUser);
    this.store.dispatch(UserActions.addUserProfile({ userProfile, shouldUpdateCompletedTasks }));
  }

  getUser() {
    return;
  }

  googleLogin() {
    return;
  }

  logout() {
    this.store.dispatch(UserActions.logout())
  }

  toggleTask(taskId: string) {
    this.completedTasks$.pipe(take(1), tap(completedTasks => completedTasks.includes(taskId)
    ? this.store.dispatch(UserActions.removeCompletedTask({ taskId }))
    : this.store.dispatch(UserActions.addCompletedTasks({ taskIds: [taskId] })))).subscribe();
  }
}
