import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State as UserState, userKey } from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>(userKey);

export const selectUserProfile = createSelector(
    selectUserState,
    state => state.userProfile
);

export const selectCompletedTasks = createSelector(
    selectUserState,
    state => state.completedTasks
);

export const selectLoading = createSelector(
    selectUserState,
    state => state.loading
);