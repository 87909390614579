import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';

import { TOKEN_NAME } from './shared/core/auth/auth.service';

const uri = 'https://designers-journey.hasura.app/v1/graphql';

@NgModule({
  exports: [ApolloModule, HttpLinkModule]
})
export class GraphQLModule {
  constructor(apollo: Apollo, httpLink: HttpLink) {
    const http = httpLink.create({ uri });

    const authMiddleware = new ApolloLink((operation, forward) => {
      const token = localStorage.getItem(TOKEN_NAME);
      let headers = new HttpHeaders();
      if (token) {
        headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      }
      operation.setContext({
        headers
      });

      return forward(operation);
    });

    apollo.create({
      link: concat(authMiddleware, http),
      cache: new InMemoryCache()
    });
  }
}
