export interface UserProfile {
  id: string;
  name: string;
  email: string;
  profileImage: string;
  userRole: UserRoles;
}

export interface Auth0UserProfile {
  'https://hasura.io/hwt/claims': HasuraClaims;
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  locale: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
}

export interface HasuraClaims {
  'x-hasura-default-role': UserRoles;
  'x-hasura-allowed-roles': UserRoles[];
  'x-hasura-user-id': string;
}

export enum UserRoles {
  USER = 'user',
  ADMIN = 'admin'
}
