import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectAll, selectEntities, selectIds, selectTotal, State as TaskState, tasksKey } from './tasks.reducer';

export const selectTaskState = createFeatureSelector<TaskState>(tasksKey);

export const selectSelectedTaskId = createSelector(
  selectTaskState,
  state => state.selectedTaskId
);

export const selectLoadingState = createSelector(
  selectTaskState,
  state => state.loading
);

export const selectTaskIds = createSelector(
  selectTaskState,
  selectIds
);

export const selectTaskEntities = createSelector(
  selectTaskState,
  selectEntities
);

export const selectAllTasks = createSelector(
  selectTaskState,
  selectAll
);

export const selectTaskTotal = createSelector(
  selectTaskState,
  selectTotal
);

export const selectTotalCount = createSelector(
  selectTaskState,
  state => state.totalCount
);

export const selectSelectedTask = createSelector(
  selectAllTasks,
  selectSelectedTaskId,
  (tasks, taskId) => tasks.find(task => task.id === taskId)
);

export const selectTasksByCategory = createSelector(
    selectAllTasks,
    (tasks, categoryId) => tasks.filter(task => task.categoryId === categoryId)
);
