import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { isNil, omitBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
    AllCategoriesQuery,
    allCategoriesQuery,
    DeleteCategoriesMutationResponse,
    deleteCategoryMutation,
    insertCategoryMutation,
    InsertCategoryMutationResponse,
    updateCategoryMutation,
    UpdateCategoryMutationResponse,
} from './category.graphql';
import { Category } from './category.interface';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private apollo: Apollo) {}

  getCategories$(options = {}): Observable<Category[]> {
    return this.apollo.watchQuery<AllCategoriesQuery>({
      query: allCategoriesQuery,
      ...options
    }).valueChanges.pipe(filter(query => !!query), map(query => query.data.categories));
  }

  addCategory(category: Category) {
    return this.apollo.mutate<InsertCategoryMutationResponse>({
      mutation: insertCategoryMutation,
      variables: { objects: omitBy(category, isNil) }
    }).pipe(filter(mutation => !!mutation), map(mutation => mutation.data.insert_categories.returning));
  }

  updateCategory(id: string, category: Category) {
    return this.apollo.mutate<UpdateCategoryMutationResponse>({
      mutation: updateCategoryMutation,
      variables: { id, category: omitBy(category, isNil) }
    }).pipe(filter(mutation => !!mutation), map(mutation => mutation.data.update_categories.returning));
  }

  deleteCategory(id: string) {
    return this.apollo.mutate<DeleteCategoriesMutationResponse>({
      mutation: deleteCategoryMutation,
      variables: {id}
    }).pipe(
    filter(mutation => !!mutation),
    map(mutation => mutation.data.delete_categories.returning),
    map(categories => categories.map(category => category.id)));
  }
}
