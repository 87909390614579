import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Category } from '../../core/categories/category.interface';
import * as CategoriesActions from './categories.actions';

export const categoriesKey = 'categories';

export interface State extends EntityState<Category> {
    selectedCategoryId: string;
    loading: boolean;
    error: any;
}

export function sortBySortOrder(a: Category, b: Category) {
  return a.sortOrder - b.sortOrder;
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  sortComparer: sortBySortOrder,
});

export const initialState: State = adapter.getInitialState({
  selectedCategoryId: null,
  loading: false,
  error: null
});

const categoriesReducer = createReducer(
    initialState,
    on(CategoriesActions.selectCategory, (state, { categoryId }) => ({...state, selectedCategoryId: categoryId })),
    on(CategoriesActions.loadCategories, (state) => ({...state, loading: true})),
    on(CategoriesActions.loadCategoriesSuccess, (state, { categories }) => adapter.setAll(categories, {...state, loading: false})),
    on(CategoriesActions.loadCategoriesError, (state, { error }) => ({...state, loading: false, error })),
    on(CategoriesActions.addCategoryError, (state) => ({...state, loading: true})),
    on(CategoriesActions.addCategoryError, (state, { error }) => ({...state, loading: false, error })),
    on(CategoriesActions.updateCategory, (state) => ({...state, loading: true})),
    on(CategoriesActions.updateCategoryError, (state, { error }) => ({...state, loading: false, error })),
    on(CategoriesActions.deleteCategory, (state) => ({...state, loading: true})),
    on(CategoriesActions.deleteCategoryError, (state, { error }) => ({...state, loading: false, error })),
);

export function reducer(state: State | undefined, action: Action) {
    return categoriesReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
