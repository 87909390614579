<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="selectedCategory$ | async as selectedCategory; else chooseCategory">{{selectedCategory.name}}</mat-card-title>
    <ng-template #chooseCategory><mat-card-title>Choose Category</mat-card-title></ng-template>
  </mat-card-header>
  <mat-divider></mat-divider>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
    </mat-form-field>
    <mat-divider></mat-divider>
    <table #table mat-table [dataSource]="tasks$ | async" [cdkDropListData]="tasks$ | async" [trackBy]="trackTable" (cdkDropListDropped)="onListDrop($event)" cdkDropList>
      <ng-container matColumnDef="sortOrder">
        <th mat-header-cell *matHeaderCellDef disableClear>#</th>
        <td mat-cell *matCellDef="let element">{{ element.sortOrder }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Achievement</th>
        <td mat-cell *matCellDef="let element">
          <div class="list-title" [innerHTML]="element.name"></div>
          <div class="list-desc" [innerHTML]="element.description"></div>
        </td>
      </ng-container>
      <!-- 
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>
      -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="warn" (click)="deleteTask(row); $event.stopImmediatePropagation();"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectTask(row)" [class.highlight]="(selectedTask$ | async)?.id === row.id" [cdkDragData]="row" cdkDrag></tr>
    </table>
  </mat-card>
