import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { filter, map } from 'rxjs/operators';

import { addCompletedTasksMutation, loadCompletedTasksQuery, removeCompletedTaskMutation } from './user.graphql';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apollo: Apollo) {}

  loadCompletedTasks(userId: string) {
    return this.apollo.watchQuery<any>({
      query: loadCompletedTasksQuery,
      variables: { userId }
    }).valueChanges.pipe(filter(query => !!query), map(query => query.data.users_by_pk && query.data.users_by_pk.completedTasks || []));
  }

  addCompletedTasks(userId: string, taskIds: string[]) {
    return this.apollo.mutate<any>({
      mutation: addCompletedTasksMutation,
      variables: { userId, taskIds }
    }).pipe(filter(mutation => !!mutation), map(mutation => mutation.data.update_users.returning[0] && mutation.data.update_users.returning[0].completedTasks || []));
  }

  removeCompletedTask(userId: string, taskId: string) {
    return this.apollo.mutate<any>({
      mutation: removeCompletedTaskMutation,
      variables: { userId, taskId }
    }).pipe(filter(mutation => !!mutation), map(mutation => mutation.data.update_users.returning[0] && mutation.data.update_users.returning[0].completedTasks || []));
  }
}
