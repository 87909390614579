import { CategoriesFacade } from '../shared/state/categories/categories.facade';
import { Component, OnInit } from '@angular/core';
import { TasksFacade } from '../shared/state/tasks/tasks.facade';
import { Category } from '../shared/core/categories/category.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  categories$: Observable<Category[]> = this.categoriesFacade.allCategories$;
  selectedCategory$: Observable<any> = this.categoriesFacade.selectedCategory$;

  constructor(public tasksFacade: TasksFacade, public categoriesFacade: CategoriesFacade) { }

  ngOnInit() {
    this.categoriesFacade.loadCategories();
  }

  selectCategory(category: Category) {
    this.tasksFacade.loadTasks(category.id);
    this.categoriesFacade.selectCategory(category.id);
    this.tasksFacade.selectTask(null);
  }

  deselectState() {
    this.categoriesFacade.selectCategory(null);
    this.tasksFacade.selectTask(null);
  }
}
