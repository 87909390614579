import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Category } from 'src/app/shared/core/categories/category.interface';

@Component({
    selector: 'app-tasks-category',
    templateUrl: './tasks-category.component.html',
    styleUrls: ['./tasks-category.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksCategoryComponent implements OnChanges {
    @Input() categories: Category[];
    @Input() selectedCategory: Category;
    @Input() taskIds: string[] = [];
    @Input() totalTasksCount: number;
    @Input() completedTasks: string[] = [];
    @Output() selectCategory = new EventEmitter<Category>();

    totalTasksCompletedCount: number;
    tasksCompletedInCategoryCount: number;

  ngOnChanges(changes: SimpleChanges) {
    /* 
    if (changes.completedTasks && changes.completedTasks.currentValue) {
      this.totalTasksCompletedCount = this.completedTasks.length;
    }
    if (changes.completedTasks && changes.completedTasks.currentValue && this.taskIds) {
      this.tasksCompletedInCategoryCount = Array.from(new Set(this.completedTasks)).filter(taskId => this.taskIds.includes(taskId)).length;
    }
    */
    // JUST RUN IT EVERY TIME
    this.totalTasksCompletedCount = this.completedTasks.length;
    this.tasksCompletedInCategoryCount = Array.from(new Set(this.completedTasks)).filter(taskId => this.taskIds.includes(taskId)).length;
  }

    emitSelectCategory(category: Category) {
        this.selectCategory.emit(category);
    }
}
