<!--<h2 mat-dialog-title>Sign in</h2>-->
<mat-dialog-content>
  <ng-container *ngIf="formMode === 'sign-in'">
    <ng-container *ngIf="authFormStatus !== 'success'">
      <div class="social-buttons-container">
        <button class="social-sign-button" (click)="signInWithGoogle()" mat-stroked-button>
          <img alt="" ngSrc="./assets/google-icon.svg" width="16" height="16" />
          Continue with Google
        </button>
      </div>
      <div class="text-divider">
        <span>or Sign in with Email</span>
      </div>

      <form [formGroup]="authForm" class="auth-form">
        <div class="form-input-container">
          <div class="form-input-label">
            <span>Email*</span>
          </div>
          <input #email class="form-input" matInput formControlName="email" placeholder="mail@website.com" />
          <div class="form-input-error-container" *ngIf="authForm.get('email').touched && authForm.get('email').invalid">
            <mat-error *ngIf="authForm.get('email').hasError('required')"> Email is required </mat-error>
            <mat-error *ngIf="authForm.get('email').hasError('email')"> Valid email address required </mat-error>
          </div>
        </div>

        <div class="form-input-container">
          <div class="form-input-label">
            <span>Password*</span>
            <span class="forgot-password" (click)="selectFormMode('forgot-password')">Forgot password?</span>
          </div>
          <input #password class="form-input" type="password" matInput formControlName="password" placeholder="Min. 8 characters" />
          <div class="form-input-error-container" *ngIf="authFormStatus === 'error' || authForm.get('password').touched && authForm.get('password').invalid">
            <mat-error *ngIf="authFormStatus === 'error'"> {{authFormMessage}} </mat-error>
            <mat-error *ngIf="authForm.get('password').hasError('required')"> Password is required </mat-error>
            <mat-error *ngIf="authForm.get('password').hasError('minlength')"> Password must be at least 8 characters </mat-error>
          </div>
        </div>

        <div class="email-form-button-container">
          <!-- 
          <button mat-flat-button [disabled]="authForm.invalid || authFormStatus === 'loading'" color="primary" (click)="authFormSubmit('create-user')">
            <span>Register</span>
          </button>

          <button mat-flat-button [disabled]="authForm.invalid || authFormStatus === 'loading'" color="primary" (click)="authFormSubmit('sign-in')">
            <span>Sign In</span>
          </button>
        -->
          <button mat-flat-button [disabled]="authForm.invalid || authFormStatus === 'loading'" color="primary" (click)="authFormSubmit('create-user')">
            <span>Register</span>
          </button>

          <button mat-flat-button [disabled]="authForm.invalid || authFormStatus === 'loading'" color="primary" (click)="authFormSubmit('sign-in')">
            <span>Sign In</span>
          </button>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="authFormStatus === 'success'">
      <div class="auth-form-success-container">
        <mat-icon class="checkmark-icon">check_circle</mat-icon>
        Success!
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="formMode === 'forgot-password'">
    <div class="forgot-password-container">
      <div class="back-button" *ngIf="resetPasswordStatus !== 'loading'" (click)="onForgotPasswordBackButton()">
        &lt; Back
      </div>
      <form [formGroup]="forgotPasswordForm" class="forgot-password-form">
        <div class="form-input-container">
          <div class="form-input-label">
            <span>Email*</span>
          </div>
          <input #email class="form-input" matInput formControlName="email" placeholder="mail@website.com" />
          <div class="form-input-error-container" *ngIf="resetPasswordStatus === 'error' || forgotPasswordForm.get('email').touched && forgotPasswordForm.get('email').invalid">
            <mat-error *ngIf="resetPasswordStatus === 'error'"> {{resetPasswordMessage}} </mat-error>
            <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')"> Email is required </mat-error>
            <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')"> Valid email address required </mat-error>
          </div>
        </div>

        <div class="email-form-button-container">
          <button mat-flat-button [disabled]="forgotPasswordForm.invalid || !!resetPasswordStatus" color="primary" (click)="forgotPasswordFormSubmit()">
            <ng-container [ngSwitch]="resetPasswordStatus">
              <ng-container *ngSwitchCase="'loading'">
                <span>Loading...</span>
              </ng-container>
              <ng-container *ngSwitchCase="'success'">
                <span>Reset link sent!</span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span>Reset Password</span>
              </ng-container>
            </ng-container>
          </button>
        </div>
      </form>
    </div>
  </ng-container>
</mat-dialog-content>
