import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from 'src/app/shared/core/categories/category.interface';
import { CategoriesFacade } from 'src/app/shared/state/categories';

@Component({
  selector: 'app-admin-list-category',
  templateUrl: './admin-list-category.component.html',
  styleUrls: ['./admin-list-category.component.scss']
})
export class AdminListCategoryComponent {
  selectedCategory$ = this.categoriesFacade.selectedCategory$;
  categories$: Observable<Category[]> = this.categoriesFacade.allCategories$;

  displayedColumns = ['sortOrder', 'title', 'description'];

  constructor(private categoriesFacade: CategoriesFacade) { }

  applyFilter(filterValue: string) {
    return;
  }

  onListDrop(event: CdkDragDrop<Category[]>) {
    const category = event.item.data;
    delete category.__typename;
    this.categoriesFacade.updateCategory(category.id, {
      ...category,
      sortOrder: event.currentIndex + event.container.data.sort((a, b) => a.sortOrder - b.sortOrder)[0].sortOrder
    });
  }

  selectCategory(category: Category) {
    this.categoriesFacade.selectCategory(category.id);
  }

  deleteCategory(category: Category) {
    this.categoriesFacade.deleteCategory(category.id);
  }

  trackTable(index, category) {
    return category.id;
  }
}
