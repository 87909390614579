import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { Expo, Linear, TweenMax } from 'gsap/all';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

import { AuthService } from './shared/core/auth/auth.service';
import { UserRoles } from './shared/core/user/user.interface';
import { CategoriesFacade } from './shared/state/categories/categories.facade';
import { TasksFacade } from './shared/state/tasks/tasks.facade';
import { UserFacade } from './shared/state/user';
import {MatDialog} from '@angular/material';
import {AuthDialogComponent} from './shared/components/auth-dialog/auth-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  color = { h: 0, s: 40, l: 50 };
  rgbColor: string;

    userProfile$ = this.userFacade.userProfile$;
    loading$: Observable<boolean> = merge(
        this.categoriesFacade.loading$,
        this.tasksFacade.loading$,
        this.userFacade.loading$
    );
    unsubscribe$ = new Subject();

    ADMIN_ROLE = UserRoles.ADMIN;

    links = [
        {path: '/admin', icon: 'lock', label: 'Admin'},
        {path: '/tasks', icon: 'assignment', label: 'Tasks'}
    ];

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private userFacade: UserFacade,
        private categoriesFacade: CategoriesFacade,
        private tasksFacade: TasksFacade,
        public auth: AuthService,
        private renderer: Renderer2,
        private el: ElementRef,
        @Inject(DOCUMENT) private document: Document,
        private dialog: MatDialog
    ) {}

    ngOnDestroy() {
        this.unsubscribe$.next();
    }

    ngOnInit() {
        this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cd.detectChanges());
    }

    ngAfterViewInit() {
      const targetHeader = this.el.nativeElement.querySelector('#header-colorize');
      const targetHead = this.document.querySelector('#customCSS');
      const targetNav = this.el.nativeElement.querySelector('.category-item');

      TweenMax.to(this.color, 60, {
        h: 360,
        ease: Linear.easeNone,
        repeat: -1,
        onUpdate: () => {
          this.rgbColor = `hsl(${this.color.h}, ${this.color.s}%, ${this.color.l}%)`;
          this.renderer.setStyle(targetHeader, 'backgroundColor', this.rgbColor);
          if (targetHead) {
            targetHead.innerHTML = `.category-item.highlight .colorize { background-color: ${this.rgbColor} !important; }`;
          }
        }
      });
    }

  deselectState() {
        this.categoriesFacade.selectCategory(null);
        this.tasksFacade.selectTask(null);
    }
    loginIconOver() {
        TweenMax.to( "#login-icon #login-arrow", 0.5, { x: 4, ease: Expo.easeOut } );
        TweenMax.to( "#login-icon #login-triangle", 1, { fill: "#FFFFFF", autoAlpha: 1, ease: Expo.easeNone } );
    }
    loginIconOut() {
        TweenMax.to( "#login-icon #login-arrow", 0.5, { x: 0, ease: Expo.easeOut } );
        TweenMax.to( "#login-icon #login-triangle", 1, { fill: "#FFFFFF", autoAlpha: 1, ease: Expo.easeNone } );
    }

    openAuthDialog() {
      this.dialog.open(AuthDialogComponent, {
        // height: '400px',
        width: '380px',
      })
    }
}
