<mat-tab-group (selectedTabChange)="deselectState()">
  <mat-tab label="Tasks">
    <div class="tasks-container">
      <div class="list-task">
        <mat-card>
        <mat-form-field *ngIf="categories$ | async as categories">
          <mat-select placeholder="Choose Category" (selectionChange)="selectCategory($event.value)">
            <mat-option *ngFor="let category of categories" [value]="category">
              {{ category.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card>
        <app-admin-list-task>
        </app-admin-list-task>
      </div>
      <div class="detail-task">
        <app-admin-detail-task>
        </app-admin-detail-task>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Categories">
    <div class="categories-container">
      <div class="list-category">
        <app-admin-list-category>
        </app-admin-list-category>
      </div>
      <div class="detail-category">
        <app-admin-detail-category>
        </app-admin-detail-category>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
