import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {CategoriesFacade} from './categories';
import {loadCategories} from './categories/categories.actions';
import {loadTotalTasksCount} from './tasks/tasks.actions';

@Injectable()
export class AppEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      withLatestFrom(this.categoriesFacade.allCategories$),
      switchMap(([_, categories]) => categories && !categories.length ? [loadTotalTasksCount(), loadCategories()] : [])
    )
  );

  constructor(
    private actions$: Actions,
    private categoriesFacade: CategoriesFacade
  ) {}
}
