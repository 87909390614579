<div class="tasks-container">
    <div class="tasks-category">
        <app-tasks-category [categories]="categories$ | async" [selectedCategory]="selectedCategory$ | async" (selectCategory)="selectCategory($event)" [taskIds]="taskIds$ | async" [completedTasks]="completedTasks$ | async" [totalTasksCount]="totalTasksCount$ | async" class="primary"></app-tasks-category>
    </div>
    <app-tasks-category-sidenav [categories]="categories$ | async" [selectedCategory]="selectedCategory$ | async" (selectCategory)="selectCategory($event)" [taskIds]="taskIds$ | async" [completedTasks]="completedTasks$ | async" [totalTasksCount]="totalTasksCount$ | async"></app-tasks-category-sidenav>
    <div class="tasks-list">
        <app-tasks-list [selectedCategory]="selectedCategory$ | async" [tasks]="tasks$ | async" [completedTasks]="completedTasks$ | async"></app-tasks-list>
    </div>
    <div class="tasks-category">
        <app-tasks-category [categories]="categories$ | async" [selectedCategory]="selectedCategory$ | async" (selectCategory)="selectCategory($event)" [taskIds]="taskIds$ | async" [completedTasks]="completedTasks$ | async" [totalTasksCount]="totalTasksCount$ | async" class="secondary"></app-tasks-category>
    </div>
</div>
