import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { Task } from 'src/app/shared/core/tasks/task.interface';
import { TasksFacade } from 'src/app/shared/state/tasks';
import { CategoriesFacade } from 'src/app/shared/state/categories';
import { tap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-admin-detail-task',
  templateUrl: './admin-detail-task.component.html',
  styleUrls: ['./admin-detail-task.component.scss']
})
export class AdminDetailTaskComponent {
  selectedTask$ = this.tasksFacade.currentTask$.pipe(filter(task => !!task), tap(task => this.taskForm.patchValue(task)));
  categories$ = this.categoriesFacade.allCategories$;

  taskForm: UntypedFormGroup;

  constructor(private tasksFacade: TasksFacade, private categoriesFacade: CategoriesFacade, private fb: UntypedFormBuilder) {
    this.taskForm = fb.group({
      id: [null],
      name: ['', Validators.required],
      description: [''],
      categoryId: ['', Validators.required],
      sortOrder: [null]
    });
  }

  save(task: Task) {
    if (task.id) {
      this.tasksFacade.updateTask(task.id, task);
    } else {
      this.tasksFacade.addTask(task);
    }
  }

  cancel() {
    this.taskForm.reset();
    this.tasksFacade.selectTask(null);
  }
}
