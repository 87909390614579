
<mat-progress-bar class="loading-bar" mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
<mat-toolbar color="primary" id='header'>
    <div id="header-colorize"></div>

    <div id="logo">
        <a routerLink="/">The Designer's Journey</a>
        <img src="assets/header-shadow.png" class="logo-shadow"/>
    </div>
    <div id="logo-mobile">
        <a routerLink="/">TDJ</a>
    </div>
    <div *ngIf="userProfile$ | async as user; else showLogin" id="login-name">
        <!-- 
        <div id="logout-shadow">
            <img src="assets/header-shadow.png" class="logout-shadow"/>
        </div>
        -->
        <img src="assets/header-shadow.png" class="logout-shadow"/>
        <button routerLink="/admin" *ngIf="user.userRole === ADMIN_ROLE" mat-mini-fab id="admin-button">
          <mat-icon>security</mat-icon>
        </button>
        <!-- 
        <button (click)="userFacade.logout()" mat-icon-button>
          <img class="logout-button-img" [src]="user.profileImage || 'assets/default-profile-image.png'" title="LOG OUT" />
        </button>
        -->
        <div (click)="userFacade.logout()" class="logout-item">Log Out</div>
        <div id="logout-icon" (click)="userFacade.logout()" title="LOG OUT">
          <svg viewBox="0 0 50 50">
              <rect id="logout-background" x="17.5" y="17.5" width="15" height="15"/>
              <path id="logout-shield" d="M23.1,26.9L20.3,24c-0.3-0.3-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1l3.6,3.6
                c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5l7.4-7.4c0.3-0.3,0.4-0.6,0.4-0.9c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4
                c-0.4,0-0.7,0.1-1,0.4L23.1,26.9z M25,41.8c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4-0.1-0.5-0.1c-3.8-1.3-6.8-3.7-9-7.1
                c-2.2-3.4-3.4-7.1-3.4-11.1v-8.1c0-0.7,0.2-1.3,0.6-1.8c0.4-0.5,0.9-0.9,1.5-1.2l10.2-3.8c0.4-0.1,0.8-0.2,1.1-0.2
                c0.4,0,0.7,0.1,1.1,0.2l10.2,3.8c0.6,0.2,1.1,0.6,1.5,1.2c0.4,0.5,0.6,1.2,0.6,1.8v8.1c0,4-1.1,7.7-3.4,11.1
                c-2.2,3.4-5.2,5.8-9,7.1c-0.2,0.1-0.3,0.1-0.5,0.1S25.2,41.8,25,41.8z"/>
          </svg>
      </div>
    </div>

    <ng-template #showLogin>
        <!--
        <button (click)="auth.login()" color="secondary" mat-mini-fab>
            <mat-icon>person</mat-icon>
        </button>
         -->
        <div id="login">
            <img src="assets/header-shadow.png" class="login-shadow"/>
            <div (click)="openAuthDialog()" (mouseenter)='loginIconOver()' (mouseleave)='loginIconOut()' class="login-item">SIGN UP / LOGIN</div>
            <!--
            <div class="login-item-divider">|</div>
            <div (click)="auth.login()" class="login-item">Login</div>
            -->
            <div (click)="openAuthDialog()" (mouseenter)='loginIconOver()' (mouseleave)='loginIconOut()' id="login-icon" title="LOG IN">
                <svg viewBox="0 0 50 50">
                    <path id="login-triangle" d="M22.23,9.84L6.57,36.96c-1.23,2.13,0.31,4.79,2.77,4.79h31.32c2.46,0,4-2.66,2.77-4.79L27.77,9.84
                    C26.54,7.71,23.46,7.71,22.23,9.84z"/>
                    <path id="login-arrow" d="M28.47,26.89l-2.08-2.08c-0.43-0.43-0.43-1.13,0-1.56c0.43-0.43,1.13-0.43,1.56,0l3.98,3.98
                    c0.43,0.43,0.43,1.13,0,1.56l-3.98,3.98c-0.43,0.43-1.13,0.43-1.56,0c-0.43-0.43-0.43-1.13,0-1.56l2.08-2.1h-9.61
                    c-0.61,0-1.11-0.5-1.11-1.11c0-0.61,0.5-1.11,1.11-1.11H28.47z"/>
                </svg>
            </div>
            <!--
            <button (click)="auth.login()" mat-icon-button><img class="logout-button-img" src="./assets/icon.png" /></button>
            -->
        </div>
    </ng-template>
</mat-toolbar>

<div class="app-content" [class.disable-interaction]="loading$ | async">
  <router-outlet></router-outlet>
</div>
<div id="loader" mode="indeterminate" *ngIf="loading$ | async">
  <div class="loader-ripple"><div></div><div></div></div>
</div>
<div class="footer">
  <ng-template #showLoginFooter>
    <!-- <p class="detail" style="margin-top:20px;"><a href="#" (click)="auth.signInWithGoogle()">Sign Up</a> or <a href="#" (click)="auth.signInWithGoogle()">Login</a> to update and access your progress from any computer or device.</p> -->
    <div class="detail" style="margin-top:20px;"><div (click)="openAuthDialog()">Sign Up</div> or <div (click)="openAuthDialog()">Login</div> to update and access your progress from any computer or device.</div>
  </ng-template>
  <div *ngIf="userProfile$ | async; else showLoginFooter"></div>
  <h2>Have an idea for an achievement?</h2>
  <p>Tweet/DM <a href="https://www.twitter.com/shanemielke" target="_blank">@shanemielke</a> for consideration.</p>
  <h2>Buy the book</h2>
  <p><a href="https://shop.shanemielke.com" target="_blank"><img src="./assets/book.jpg" width="200" alt="Book Version"></a></p>
</div>
