<form [formGroup]="taskForm">
  <mat-card>
    <mat-card-header>
      <mat-card-title *ngIf="selectedTask$ | async as selectedTask; else newTask">{{selectedTask.name}}</mat-card-title>
      <ng-template #newTask><mat-card-title>Add Task</mat-card-title></ng-template>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
    <mat-form-field>
      <input #name matInput formControlName="name" placeholder="Name" />
      <mat-error *ngIf="taskForm.get('name').invalid"> Name is <strong>required</strong> </mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput formControlName="description" placeholder="Description"></textarea>
      <mat-error *ngIf="taskForm.get('description').invalid"> Description is <strong>required</strong> </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-select formControlName="categoryId" placeholder="Category">
        <mat-option *ngFor="let category of categories$ | async" [value]="category.id">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="number" formControlName="sortOrder" placeholder="Sort Order" />
    </mat-form-field>
  </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="save(taskForm.value)" [disabled]="taskForm.invalid">
        Save
      </button>
      <button mat-raised-button color="secondary" (click)="cancel()">Cancel</button>
    </mat-card-actions>
  </mat-card>
</form>
