<div class="categories">
    <div id="totals">
        <div class="totals">
            <strong>Total Goals: </strong> {{totalTasksCompletedCount}} / {{totalTasksCount}}<div class="spacer">—</div><strong>Category: </strong> {{tasksCompletedInCategoryCount}} / {{taskIds.length}}
        </div>
    </div>
    <div class="category-item" *ngFor="let category of categories" (click)="emitSelectCategory(category)" [ngClass]="{'highlight': selectedCategory?.id === category?.id}">
        <div class="colorize"></div>
        <div class="label">{{category.name}}</div>
    </div>
    <div class="category-label">SELECT A CATEGORY ABOVE</div>
</div>
