import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';

import { Category } from '../../core/categories/category.interface';
import { addCategory, deleteCategory, loadCategories, selectCategory, updateCategory } from './categories.actions';
import { State as CategoryState } from './categories.reducer';
import { selectAllCategories, selectLoadingState, selectSelectedCategory } from './categories.selector';

@Injectable({
  providedIn: 'root'
})
export class CategoriesFacade {
  allCategories$ = this.store.pipe(select(selectAllCategories));
  selectedCategory$ = this.store.pipe(select(selectSelectedCategory));
  loading$ = this.store.pipe(select(selectLoadingState), distinctUntilChanged());

  constructor(private store: Store<CategoryState>) {}

  loadCategories() {
    return this.store.dispatch(loadCategories());
  }

  selectCategory(categoryId: string) {
    return this.store.dispatch(selectCategory({categoryId}));
  }

  addCategory(category: Category) {
    return this.store.dispatch(addCategory({category}));
  }

  updateCategory(id: string, changes: Category) {
    return this.store.dispatch(updateCategory({id, changes}));
  }

  deleteCategory(id: string) {
    return this.store.dispatch(deleteCategory({id}));
  }
}
