import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Expo, TweenMax } from 'gsap/TweenMax';
import { Category } from 'src/app/shared/core/categories/category.interface';
import { UserFacade } from 'src/app/shared/state/user';

@Component({
    selector: 'app-tasks-list',
    templateUrl: './tasks-list.component.html',
    styleUrls: ['./tasks-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksListComponent implements OnInit, OnChanges {
    @Input() selectedCategory: Category;
    @Input() tasks: string[];
    @Input() completedTasks: string[];
    displayedColumns: string[] = ['sortOrder', 'name', 'select'];

    dataSource = new MatTableDataSource();

    constructor(private userFacade: UserFacade) {}

    ngOnInit() {
        TweenMax.set( '#header-category', {  autoAlpha: 0 } );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedCategory && changes.selectedCategory.currentValue) {
            TweenMax.fromTo( "#header-category", 1, { x: 100, autoAlpha: 0 }, { x: 0, autoAlpha: 1, ease: Expo.easeOut } );
        }
        if (changes.tasks && changes.tasks.currentValue) {
            this.dataSource.data = this.tasks;
        }
    }

    toggleTaskCompletion(taskId: string) {
        this.userFacade.toggleTask(taskId);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
}
