import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Task } from '../../core/tasks/task.interface';
import * as TaskActions from './tasks.actions';


export const tasksKey = 'tasks';

export interface State extends EntityState<Task> {
    selectedTaskId: string;
    totalCount: number;
    loading: boolean;
    error: any;
}

export function sortBySortOrder(a: Task, b: Task) {
  return a.sortOrder - b.sortOrder;
}

export const adapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  sortComparer: sortBySortOrder,
});

export const initialState: State = adapter.getInitialState({
  selectedTaskId: null,
  totalCount: null,
  loading: false,
  error: null
});

const tasksReducer = createReducer(
    initialState,
    on(TaskActions.selectTask, (state, { taskId }) => ({...state, selectedTaskId: taskId })),
    /* on(TaskActions.loadTotalTasksCount, (state) => ({...state, error: false})), */
    on(TaskActions.loadTotalTasksCountSuccess, (state, { totalCount }) => ({...state, totalCount})),
    on(TaskActions.loadTotalTasksCountError, (state, { error }) => ({...state, error })),
    on(TaskActions.loadTasks, (state) => ({...state, loading: true})),
    on(TaskActions.loadTasksSuccess, (state, { tasks }) => adapter.setAll(tasks, {...state, loading: false})),
    on(TaskActions.loadTasksError, (state, { error }) => ({...state, loading: false, error })),
    on(TaskActions.addTask, (state) => ({...state, loading: true})),
    on(TaskActions.addTaskError, (state, { error }) => ({...state, loading: false, error })),
    on(TaskActions.updateTask, (state) => ({...state, loading: true})),
    on(TaskActions.updateTaskError, (state, { error }) => ({...state, loading: false, error })),
    on(TaskActions.deleteTask, (state) => ({...state, loading: true})),
    on(TaskActions.deleteTaskError, (state, { error }) => ({...state, loading: false, error })),
);

export function reducer(state: State | undefined, action: Action) {
    return tasksReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
