import { Action, createReducer, on } from '@ngrx/store';

import { UserProfile } from '../../core/user/user.interface';
import * as UserActions from './user.actions';
import {uniq} from 'lodash-es'

export const userKey = 'user';

export interface State {
  userProfile: UserProfile;
  completedTasks: string[];
  loading: boolean;
  error: any;
}

export const initialState: State = {
  userProfile: null,
  completedTasks: [],
  loading: false,
  error: null
};

const tasksReducer = createReducer(
  initialState,
  on(UserActions.loadCompletedTasksSuccess, (state, { completedTasks }) => ({
    ...state,
    completedTasks,
    loading: false,
    error: null
  })),
  on(UserActions.loadCompletedTasksError, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(UserActions.addUserProfile, (state, { userProfile }) => ({
    ...state,
    userProfile
  })),
  on(UserActions.addCompletedTasks, state => ({
    ...state,
    loading: true,
    error: false
  })),
  on(UserActions.addCompletedTasksSuccess, (state, { completedTasks }) => ({
    ...state,
    completedTasks: uniq([...state.completedTasks, ...completedTasks]),
    loading: false,
    error: null
  })),
  on(UserActions.addCompletedTasksError, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(UserActions.removeCompletedTask, state => ({
    ...state,
    loading: true,
    error: false
  })),
  on(UserActions.removeCompletedTaskSuccess, (state, { completedTask }) => ({
    ...state,
    completedTasks: state.completedTasks.filter(task => task !== completedTask),
    loading: false,
    error: null
  })),
  on(UserActions.removeCompletedTaskError, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(UserActions.logout, (state) => ({
    ...state,
    loading: false,
    error: null,
    userProfile: null
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return tasksReducer(state, action);
}
