<div id="totals-sidenav">
    <div class="totals">
        <strong>Total Goals:</strong><br>
        {{totalTasksCompletedCount}} / {{totalTasksCount}}
        <!-- <br>{{tasksCompletedInCategoryCount}} / {{taskIds.length}} -->
    </div>
</div>
<div class="categories-sidenav">
    <div class="categories-sidenav-item" *ngFor="let category of categories" (click)="emitSelectCategory(category)" [ngClass]="{'highlight': selectedCategory?.id === category?.id}">
        <div class="dot"></div>
        <div class="title">{{category.name}}</div>
    </div>
</div>