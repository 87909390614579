import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import { UserService } from '../../core/user/user.service';
import * as UserActions from './user.actions';
import { UserFacade } from './user.facade';
import {uniq} from 'lodash-es'
import {AuthService} from '../../core/auth/auth.service';

@Injectable()
export class UserEffects {
  loadCompletedTasks$ = createEffect(() =>
  this.actions$.pipe(
    ofType(UserActions.addUserProfile),
    filter(action => action.shouldUpdateCompletedTasks !== false),
    withLatestFrom(this.userFacade.completedTasks$),
    switchMap(([action, completedTasks]) =>
      this.userService.loadCompletedTasks(action.userProfile.id).pipe(
        mergeMap((tasks: string[]) => {
          const tasksToAdd = uniq(completedTasks.filter(taskId => !tasks.includes(taskId)))
          return [UserActions.addCompletedTasks({ taskIds: tasksToAdd })]
        }
        ),
        catchError(error => of(UserActions.loadCompletedTasksError({ error })))
      )
    )
  )
);

  addCompletedTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.addCompletedTasks),
      withLatestFrom(this.userFacade.userProfile$),
      mergeMap(([action, userProfile]) => userProfile ?
        this.userService.addCompletedTasks(userProfile.id, action.taskIds).pipe(
          map(() =>
            UserActions.addCompletedTasksSuccess({ completedTasks: action.taskIds })
          ),
          catchError(error => of(UserActions.addCompletedTasksError({ error })))
        ) : of(UserActions.addCompletedTasksSuccess({ completedTasks: action.taskIds }))
      )
    )
  );

  removeCompletedTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.removeCompletedTask),
      withLatestFrom(this.userFacade.userProfile$),
      mergeMap(([action, userProfile]) => userProfile ?
        this.userService.removeCompletedTask(userProfile.id, action.taskId).pipe(
          map(() =>
            UserActions.removeCompletedTaskSuccess({ completedTask: action.taskId })
          ),
          catchError(error => of(UserActions.removeCompletedTaskError({ error })))
        ) : of(UserActions.removeCompletedTaskSuccess({ completedTask: action.taskId }))
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      tap(() => this.authService.logout())
    ), { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private userFacade: UserFacade
  ) {}
}
