import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';

import { CategoriesEffects } from './categories';
import { reducers } from './state.reducer';
import { TasksEffects } from './tasks';
import { UserEffects } from './user';
import { AppEffects } from './app.effects';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { user: ['completedTasks'] }
    ],
    storageKeySerializer: key => `_${key}`,
    rehydrate: true
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    EffectsModule.forRoot([AppEffects, TasksEffects, CategoriesEffects, UserEffects])
  ]
})
export class StateModule {
  constructor() {
    if (window['localStorage']) {
      localStorage.removeItem('user')
    }
  }
}
