import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { UserFacade } from '../../state/user';
import { AuthService } from '../auth/auth.service';
import { UserProfile, UserRoles } from '../user/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private userFacade: UserFacade, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      switchMap(isAuthenticated => iif(() => isAuthenticated, this.userFacade.userProfile$.pipe(filter(Boolean)), of(null))),
      map((userProfile: UserProfile) => userProfile ? userProfile.userRole === UserRoles.ADMIN : false),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.router.navigateByUrl('/');
        }
      })
    );
  }
}
