import { UserProfile } from './user.interface';
import {User} from '@angular/fire/auth';
import {ParsedToken} from '@firebase/auth';

export const mapFirebaseClaimsToUserProfile = (claims: ParsedToken = <ParsedToken>{}): UserProfile => ({
    id: claims.user_id,
    name: claims.name,
    email: claims.email,
    profileImage: claims.picture,
    userRole: claims['https://hasura.io/jwt/claims']?.['x-hasura-default-role'] || null
});
