import { ActionReducerMap } from '@ngrx/store';

import * as fromCategories from './categories';
import * as fromTasks from './tasks';
import * as fromUser from './user';

export interface RootState {
    [fromCategories.categoriesKey]: fromCategories.State;
    [fromTasks.tasksKey]: fromTasks.State;
    [fromUser.userKey]: fromUser.State;
}

export const reducers: ActionReducerMap<RootState> = {
    categories: fromCategories.reducer,
    tasks: fromTasks.reducer,
    user: fromUser.reducer
};
