import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { Task } from 'src/app/shared/core/tasks/task.interface';
import { CategoriesFacade } from 'src/app/shared/state/categories';
import { TasksFacade } from 'src/app/shared/state/tasks';

@Component({
  selector: 'app-admin-list-task',
  templateUrl: './admin-list-task.component.html',
  styleUrls: ['./admin-list-task.component.scss']
})
export class AdminListTaskComponent {
  selectedCategory$ = this.categoriesFacade.selectedCategory$;
  selectedTask$ = this.tasksFacade.currentTask$;
  tasks$ = this.tasksFacade.selectedCategoryTasks;

  displayedColumns = ['sortOrder', 'name', 'delete'];

  constructor(private tasksFacade: TasksFacade, private categoriesFacade: CategoriesFacade) { }

  applyFilter(filterValue: string) {
    return;
  }

  onListDrop(event: CdkDragDrop<Task[]>) {
    const task = event.item.data;
    delete task.__typename;
    this.tasksFacade.updateTask(task.id, {
      ...task,
      sortOrder: event.currentIndex + event.container.data.sort((a, b) => a.sortOrder - b.sortOrder)[0].sortOrder
    });
  }

  selectTask(task: Task) {
    this.tasksFacade.selectTask(task.id);
  }

  deleteTask(task: Task) {
    const deleteTask = {
      id: task.id,
      name: task.name,
      description: task.description,
      categoryId: task.categoryId,
      sortOrder: task.sortOrder
    };
    this.tasksFacade.deleteTask(deleteTask);
  }

  trackTable(index, task) {
    return task.id;
  }
}
