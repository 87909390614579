import { MaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TasksComponent } from './tasks/tasks.component';

import { AdminComponent } from './admin/admin.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AdminListCategoryComponent } from './admin/admin-list-category/admin-list-category.component';
import { AdminListTaskComponent } from './admin/admin-list-task/admin-list-task.component';
import { AdminDetailCategoryComponent } from './admin/admin-detail-category/admin-detail-category.component';
import { AdminDetailTaskComponent } from './admin/admin-detail-task/admin-detail-task.component';
import { TasksListComponent } from './tasks/tasks-list/tasks-list.component';
import { TasksCategoryComponent } from './tasks/tasks-category/tasks-category.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { StateModule } from './shared/state/state.module';
import { TasksCategorySidenavComponent } from './tasks/tasks-category-sidenav/tasks-category-sidenav.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { AuthDialogComponent } from './shared/components/auth-dialog/auth-dialog.component';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    TasksComponent,
    AdminComponent,
    AdminListCategoryComponent,
    AdminListTaskComponent,
    AdminDetailCategoryComponent,
    AdminDetailTaskComponent,
    TasksListComponent,
    TasksCategoryComponent,
    TasksCategorySidenavComponent,
    AuthDialogComponent
  ],
  imports: [
    BrowserModule,
    GraphQLModule,
    StateModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DragDropModule,
    FormsModule,
    NgOptimizedImage,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => getFunctions())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
