import gql from 'graphql-tag';

export const loadCompletedTasksQuery = gql`
query LoadCompletedTasks($userId: String!) {
  users_by_pk(id: $userId) {
    completedTasks
  }
}`;

export const addCompletedTasksMutation = gql`
mutation AddCompletedTasks($userId: String!, $taskIds: jsonb!) {
    update_users(where: {id: {_eq: $userId}}, _append: {completedTasks: $taskIds}) {
      returning {
        completedTasks
      }
    }
}`;

export const removeCompletedTaskMutation = gql`
mutation RemoveCompletedTask($userId: String!, $taskId: String!) {
    update_users(where: {id: {_eq: $userId}}, _delete_key: {completedTasks: $taskId}) {
      returning {
        completedTasks
      }
    }
}`;
