import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { TaskService } from '../../core/tasks/task.service';
import * as TaskActions from './tasks.actions';

@Injectable()
export class TasksEffects {

    loadTotalTasksCount$ = createEffect(() => this.actions$.pipe(
        ofType(TaskActions.loadTotalTasksCount),
        mergeMap(action => this.tasksService.getTotalTasksCount$().pipe(
            map(totalCount => TaskActions.loadTotalTasksCountSuccess({totalCount})),
            catchError(error => of(TaskActions.loadTotalTasksCountError({error})))
        ))
    ));

    loadTasks$ = createEffect(() => this.actions$.pipe(
        ofType(TaskActions.loadTasks),
        switchMap(action => this.tasksService.getTasks$(action.categoryId).pipe(
            map(tasks => TaskActions.loadTasksSuccess({tasks})),
            catchError(error => of(TaskActions.loadTasksError({error})))
        ))
    ));

    noCacheLoadTasks$ = createEffect(() => this.actions$.pipe(
        ofType(TaskActions.noCacheLoadTasks),
        switchMap(action => this.tasksService.getTasks$(action.categoryId, { fetchPolicy: 'network-only' }).pipe(
            map(tasks => TaskActions.loadTasksSuccess({tasks})),
            catchError(error => of(TaskActions.loadTasksError({error})))
        ))
    ));

    addTask$ = createEffect(() => this.actions$.pipe(
        ofType(TaskActions.addTask),
        mergeMap(action => this.tasksService.addTask(action.task).pipe(
            map(() => TaskActions.noCacheLoadTasks({ categoryId: action.task.categoryId })),
            catchError(error => of(TaskActions.addTaskError({error})))
        ))
    ));

    updateTask$ = createEffect(() => this.actions$.pipe(
        ofType(TaskActions.updateTask),
        mergeMap(action => this.tasksService.updateTask(action.id, action.changes).pipe(
            map(() => TaskActions.noCacheLoadTasks({ categoryId: action.changes.categoryId })),
            catchError(error => of(TaskActions.updateTaskError({error})))
        ))
    ));

    deleteTask$ = createEffect(() => this.actions$.pipe(
        ofType(TaskActions.deleteTask),
        mergeMap(action => this.tasksService.deleteTask(action.task.id).pipe(
            map(() => TaskActions.noCacheLoadTasks({ categoryId: action.task.categoryId })),
            catchError(error => of(TaskActions.deleteTaskError({error})))
        ))
    ));

    constructor(private tasksService: TaskService, private actions$: Actions, private store: Store<any>) {}
}
