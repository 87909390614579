import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { filter, map, shareReplay, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {Auth, updateProfile, GoogleAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, signOut, user, sendPasswordResetEmail } from '@angular/fire/auth';
import {Database, ListenEvent, ref, stateChanges} from '@angular/fire/database';
import { Functions, httpsCallable } from '@angular/fire/functions';

import {CategoriesFacade} from '../../state/categories';
import {TasksFacade} from '../../state/tasks';
import {UserFacade} from '../../state/user';
import {fromPromise} from 'rxjs/internal-compatibility';

export const TOKEN_NAME = 'id_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$ = user(this.auth)
  isAuthenticated$ = this.user$.pipe(
    map(user => !!user),
    shareReplay(1)
  )

  constructor(
    private auth: Auth,
    private fireDatabase: Database,
    private functions: Functions,
    private router: Router,
    private userFacade: UserFacade,
    private categoriesFacade: CategoriesFacade,
    private tasksFacade: TasksFacade,
  ) {
    this.localAuthSetup();
  }

  private localAuthSetup() {
    return this.auth.onAuthStateChanged(async user => {
      if (user) {

        // SHANE UPDATE NAME HERE?
        //console.log(user);
        //console.log(user.email);
        console.log(this.auth);

        //auth.currentUser.

        if (user.displayName == null) {
          console.log("WTFFFFFFF")

        }
      
        /* 
        user.updateProfile({
          displayName: "Jane Q. User"
        }).then(function() {
          // Update successful.
        }).catch(function(error) {
          // An error happened.
        });

        updateProfile(user, {
          displayName: "Jane Q. User"
        }).then(() => {
          // Profile updated!
          // ...
        }).catch((error) => {
          // An error occurred
          // ...
        });
        */
        

        // THIS UPDATES SOMETHING. JUST NOT HASURA
        updateProfile(this.auth.currentUser, {
            displayName: "Jane Q. User 55"
        }).then(() => {
          // Profile updated!
          //FirebaseAuth.getInstance().getCurrentUser().reload()
          this.auth.currentUser.reload();
        }).catch((error) => {
          // An error occurred
        });

        /* 
        admin.auth().updateUser(uid, {
          email: "modifiedUser@example.com"
        });
        */

        if (await user.getIdToken() !== localStorage.getItem(TOKEN_NAME)) {
          httpsCallable(this.functions, 'refreshClaims')().then(async () => {
            const token = await user.getIdToken(true);
            const idTokenResult = await user.getIdTokenResult();
            const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];
            if (hasuraClaim) {
              localStorage.setItem(TOKEN_NAME, token)
              this.userFacade.addProfileDetails(idTokenResult.claims, false)
            }
          })
        }

        const token = await user.getIdToken(true);
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];
        /* 
        user.updateProfile({
          displayName: "Jane Q. User"
        }).then(function() {
          // Update successful.
        }).catch(function(error) {
          // An error happened.
        });
        */
        if (hasuraClaim) {
          localStorage.setItem(TOKEN_NAME, token)
          this.userFacade.addProfileDetails(idTokenResult.claims)
        } else {
          const metadataRef = ref(this.fireDatabase, `metadata/${user.uid}/refreshTime`);

          stateChanges(metadataRef, {events: [ListenEvent.value]}).pipe(
            filter((data) => !data?.snapshot.exists()),
            switchMap(() => fromPromise(user.getIdToken(true))),
            tap((token) => localStorage.setItem(TOKEN_NAME, token)),
            switchMap(() => fromPromise(user.getIdTokenResult())),
            tap((tokenResults) => this.userFacade.addProfileDetails(tokenResults.claims)),
            take(1)
          )
          .subscribe()
        }
      }
    })
  }

  signInWithGoogle() {
    const provider = new GoogleAuthProvider()
    return signInWithPopup(this.auth, provider)
  }

  signInWithForm(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  createUserWithForm(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password)
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email)
  }

  logout() {
    signOut(this.auth)
    localStorage.removeItem(TOKEN_NAME)
  }
}
