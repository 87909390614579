import { Component, OnInit } from '@angular/core';

import { Category } from '../shared/core/categories/category.interface';
import { CategoriesFacade } from '../shared/state/categories';
import { TasksFacade } from '../shared/state/tasks';
import { UserFacade } from '../shared/state/user';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  categories$ = this.categoriesFacade.allCategories$;
  selectedCategory$ = this.categoriesFacade.selectedCategory$;
  tasks$ = this.tasksFacade.selectedCategoryTasks;
  completedTasks$ = this.userFacade.completedTasks$;
  taskIds$ = this.tasksFacade.taskIds$;
  totalTasksCount$ = this.tasksFacade.totalTasksCount$;

  constructor(
    private categoriesFacade: CategoriesFacade,
    private tasksFacade: TasksFacade,
    private userFacade: UserFacade
  ) {}

  ngOnInit() {
  }

  selectCategory(category: Category) {
    this.categoriesFacade.selectCategory(category.id);
    this.tasksFacade.loadTasks(category.id);
  }
}
