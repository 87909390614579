import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Category } from 'src/app/shared/core/categories/category.interface';
import { CategoriesFacade } from 'src/app/shared/state/categories';
import { tap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-admin-detail-category',
  templateUrl: './admin-detail-category.component.html',
  styleUrls: ['./admin-detail-category.component.scss']
})
export class AdminDetailCategoryComponent {
  selectedCategory$ = this.categoriesFacade.selectedCategory$.pipe(
    filter(category => !!category),
    tap(category => this.categoryForm.patchValue(category)));

  categoryForm: UntypedFormGroup;

  constructor(private categoriesFacade: CategoriesFacade, private fb: UntypedFormBuilder) {
    this.categoryForm = fb.group({
      id: [null],
      name: ['', Validators.required],
      description: [''],
      sortOrder: [null]
    });
  }

  save(category: Category) {
    this.categoryForm.reset();
    if (category.id) {
      this.categoriesFacade.updateCategory(category.id, category);
    } else {
      this.categoriesFacade.addCategory(category);
    }
  }

  cancel() {
    this.categoryForm.reset();
    this.categoriesFacade.selectCategory(null);
  }

  delete(id: string) {
    this.categoryForm.reset();
    this.categoriesFacade.deleteCategory(id);
  }
}
