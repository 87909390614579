import { createAction, props } from '@ngrx/store';

import { Category } from './../../core/categories/category.interface';

export const selectCategory = createAction('[Categories] Select Category', props<{ categoryId: string }>());

export const loadCategories = createAction('[Categories] Load Categories');

export const noCacheLoadCategories = createAction('[Categories] No Cache Load Categories');

export const loadCategoriesSuccess = createAction('[Categories] Load Categories Success', props<{ categories: Category[] }>());

export const loadCategoriesError = createAction('[Categories] Load Categories Error', props<{ error: any }>());

export const addCategory = createAction('[Categories] Add Category', props<{ category: Category }>());

export const addCategoryError = createAction('[Categories] Add Category Error', props<{ error: any }>());

export const updateCategory = createAction('[Categories] Update Category', props<{ id: string, changes: Category }>());

export const updateCategoryError = createAction('[Categories] Update Category Error', props<{ error: any }>());

export const deleteCategory = createAction('[Categories] Delete Category', props<{ id: string }>());

export const deleteCategoryError = createAction('[Categories] Delete Category Error', props<{ error: any }>());
