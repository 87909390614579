<mat-card>
  <mat-card-header>
    <mat-card-title>Category List</mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
    </mat-form-field>
    <mat-divider></mat-divider>
    <table #table mat-table [dataSource]="categories$ | async" [trackBy]="trackTable" [cdkDropListData]="categories$ | async" (cdkDropListDropped)="onListDrop($event)" cdkDropList>
      <ng-container matColumnDef="sortOrder">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let element">{{ element.sortOrder }}</td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectCategory(row)" [class.highlight]="(selectedCategory$ | async)?.id === row.id" [cdkDragData]="row" cdkDrag></tr>
    </table>
  </mat-card>
