import {inject, NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from './admin/admin.component';
import { TasksComponent } from './tasks/tasks.component';
import { AuthGuard } from './shared/core/guards/auth-guard.service';

const routes: Routes = [
  {path: '', component: TasksComponent},
  {path: 'admin', component: AdminComponent, canActivate: [AuthGuard]},
  {path: 'tasks', component: TasksComponent},
  {path: '**', redirectTo: '/tasks', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
