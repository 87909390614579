import { createAction, props } from '@ngrx/store';

import { Task } from './../../core/tasks/task.interface';

export const selectTask = createAction('[Tasks] Select Task', props<{ taskId: string }>());

export const loadTotalTasksCount = createAction('[Tasks] Load Total Tasks Count');

export const loadTotalTasksCountSuccess = createAction('[Tasks] Load Total Tasks Count Success', props<{ totalCount: number }>());

export const loadTotalTasksCountError = createAction('[Tasks] Load Total Tasks Count Error', props<{ error: any }>());

export const loadTasks = createAction('[Tasks] Load Tasks', props<{ categoryId: string }>());

export const noCacheLoadTasks = createAction('[Tasks] No Cache Load Tasks', props<{ categoryId: string }>());

export const loadTasksSuccess = createAction('[Tasks] Load Tasks Success', props<{ tasks: Task[] }>());

export const loadTasksError = createAction('[Tasks] Load Tasks Error', props<{ error: any }>());

export const addTask = createAction('[Tasks] Add Task', props<{ task: Task }>());

export const addTaskError = createAction('[Tasks] Add Task Error', props<{ error: any }>());

export const updateTask = createAction('[Tasks] Update Task', props<{ id: string, changes: Task }>());

export const updateTaskError = createAction('[Tasks] Update Task Error', props<{ error: any }>());

export const deleteTask = createAction('[Tasks] Delete Task', props<{ task: Task }>());

export const deleteTaskError = createAction('[Tasks] Delete Task Error', props<{ error: any }>());
